<template>
    <vx-card :title="`Outbound Purchase Order Return`">
        <div class="mb-6 vx-row">
            <vs-button @click="handleBack()" class="mt-2 ml-4" color="danger" icon-pack="feather"
                icon="icon-arrow-left">Back</vs-button>
        </div>
        <div class="flex md:flex-row">
            <div class="w-full pr-6 md:basis-1/2">
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>SR Data</span>
                    </div>
                    <div class="w-full px-4 py-2 border-black rounded vx-col sm:w-5/6 bg-grey-light">
                        <div>SR No : {{ form.sr_code ? this.form.sr_code : "" }}</div>
                        <div>SR Type : {{ Number(this.type) > 0 ? "Without Reference" : "With Reference" }}</div>
                        <div v-if="form.po_code">PO No : {{ form.po_code }}</div>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Supplier Data</span>
                    </div>
                    <div class="w-full px-4 py-2 border-black rounded vx-col sm:w-5/6 bg-grey-light">
                        <div>Supplier No : {{ form.supplier_code }}</div>
                        <div>Name : {{ form.supplier_name }}</div>
                        <div>Address : {{ form.supplier_address }}</div>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Return Data</span>
                    </div>
                    <div class="w-full px-4 py-2 border-black rounded vx-col sm:w-5/6 bg-grey-light">
                        <div>Warehouse : <span v-if="form.warehouse">{{ form.warehouse.code }} - {{ form.warehouse.name
                                }}</span></div>
                        <div>Date : {{ form.date ? formatDate(form.date) : "" }}</div>
                    </div>
                </div>
            </div>
            <div class="w-full md:basis-1/2">
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Note</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6">
                        <vs-textarea v-model="form.note" class="w-full"></vs-textarea>
                    </div>
                </div>
                <div class="mb-6 vx-row">
                    <div class="flex items-center w-full vx-col sm:w-1/6">
                        <span>Attachment</span>
                    </div>
                    <div class="w-full vx-col sm:w-5/6 upload">
                        <vs-button v-if="attachment && attachment.length > 0" class="mr-2 up btnx " type="filled"
                            color="primary" icon-pack="feather" icon="icon-plus"
                            @click="$refs.attachment.click()">Upload</vs-button>
                        <vs-button v-else class="mr-2" color="primary" icon-pack="feather" icon="icon-plus"
                            @click="$refs.attachment.click()">Upload</vs-button>
                        <vs-dropdown v-if="attachment && attachment.length > 0">
                            <vs-button class="up btn-drop" type="filled" icon="expand_more"></vs-button>
                            <vs-dropdown-menu>
                                <vs-dropdown-item @click="handleDeleteFile(index)" v-for="(item, index) in attachment"
                                    :key="index">
                                    <vs-icon icon="delete" color="danger"></vs-icon>{{ item.name }}
                                </vs-dropdown-item>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                        <input type="file" multiple ref="attachment" accept=".jpg,.jpeg, .png"
                            @change="handleChangeAttachment" style="display: none;" class="invisible appearance-none" />
                        <vs-button class="ml-5" v-if="form.attachments && form.attachments.length > 0"
                            @click="popShow = true" color="primary" type="filled">Download</vs-button>
                        <vs-popup classContent="popup-example" title="Download or delete" :active.sync="popShow">
                            <vs-list>
                                <vs-list-item v-for="(item, index) in form.attachments" :key="index" :title="item.name"
                                    class="flex">
                                    <div class="flex">
                                        <a :href="item.url"><vs-button color="success" type="flat"
                                                icon="file_download"></vs-button></a>
                                        <vs-button color="danger" @click="handleDeleteAttachment(item.id)" type="flat"
                                            icon="delete"></vs-button>
                                    </div>
                                </vs-list-item>
                            </vs-list>
                        </vs-popup>
                    </div>
                </div>
            </div>
        </div>
        <div class="mb-6 vx-row">
            <table width="100%" class="m-3 vs-table vs-table--tbody-table">
                <thead class="vs-table--thead">
                    <tr>
                        <th>
                            <div>SKU Code</div>
                        </th>
                        <th>
                            <div>SKU Description</div>
                        </th>
                        <th>HU</th>
                        <th>SR Qty</th>
                        <th>Bad Stock Qty</th>
                        <th>Batch</th>
                        <th>ED</th>
                        <th>Return Qty</th>
                        <th>Remaining</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in form.lines" :key="index" style="padding-bottom: 5px">
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input :value="form.lines[index].item_unit ? form.lines[index].item_unit.sku_code : ''"
                                disabled readonly class="w-full bg-grey-light"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input :value="form.lines[index].item_unit ? form.lines[index].item_unit.item_name : ''"
                                disabled readonly class="w-full bg-grey-light"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input :value="form.lines[index].item_unit ? form.lines[index].item_unit.name : ''"
                                disabled readonly class="w-full bg-grey-light"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input v-model="form.lines[index].quantity" disabled readonly
                                class="w-full bg-grey-light"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input
                                :value="form.lines[index].bad_qty ? form.lines[index].bad_qty : form.lines[index].quantity"
                                disabled readonly class="w-full bg-grey-light"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input v-if="type == 0"
                                :value="`${form.lines[index].batch ? form.lines[index].batch : '-'}`" disabled readonly
                                class="w-full bg-grey-light"></vs-input>
                            <CustomMSelect v-else :value="form.lines[index].batch_data" label="batch" track-by="batch"
                                :id="form.lines[index].item_unit_id" :warehouse_id="form.warehouse_id"
                                :warehouse_area_id="form.warehouse_area_id"
                                :base-url="`${$store.state.purchase.supplierReturn.itemStockPath}/item-batch-ed`"
                                @selected="(v) => {
        form.lines[index].batch_data = v;
        form.lines[index].batch = v.batch;
        form.lines[index].expired_date = v.expired_date;
        form.lines[index].warehouse_area_id = v.warehouse_area_id
    }">
                            </CustomMSelect>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input
                                :value="`${form.lines[index].expired_date ? formatDate(form.lines[index].expired_date) : '-'} `"
                                disabled readonly class="w-full bg-grey-light"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input type="number" min="0" v-model="form.lines[index].return_qty" @input="(v) => {
        form.lines[index].remaining = Number(form.lines[index].quantity) - Number(v)
    }" class="w-full"></vs-input>
                        </td>
                        <td class="td vs-table--td" style="padding: 5px">
                            <vs-input :value="`${form.lines[index].remaining ? form.lines[index].remaining : '0'}`"
                                disabled readonly class="w-full bg-grey-light"></vs-input>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mb-6 vx-row">
            <div class="w-full vx-col sm:w-1/1">
                <div
                    style="position: absolute; left: 50%; -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); ">
                    <vs-button class="mb-2 mr-3" @click="handleSubmit(false)">Save</vs-button>
                    <vs-button class="mb-2 mr-3" @click="handleSubmit(true)">Save and Confirm</vs-button>
                </div>
            </div>
        </div>
    </vx-card>
</template>

<script>
import Date from "@/components/Date.vue";
import CustomMSelect from "./components/CustomMultiSelect.vue";
import vSelect from "vue-select";
import moment from "moment";
import Datepicker from "vuejs-datepicker";

export default {
    components: {
        Date,
        CustomMSelect,
        vSelect,
        Datepicker,
    },
    mounted() {
        this.id = Number(this.$route.params.id);
        this.form.id = this.id;
        this.return_id = Number(this.$route.query.r);
        this.form.return_id = this.return_id
        this.type = Number(this.$route.query.t);
        this.form.return_type = this.type
        this.initData();
    },
    data() {
        return {
            id: null,
            return_id: null,
            type: null,
            attachment: null,
            popShow: false,
            disabledDates: {
                to: moment().subtract(1, "days").toDate()
            },
            form: {
                code: "",
                attachments: null,
                id: 0,
                note: null,
                date: moment().toDate(),
                return_type: 0,
                return_id: 0,
                lines: []
            },
        }
    },
    methods: {
        handleLog(v) { console.log(v); },
        handleChangeAttachment(event) {
            this.attachment = event.target.files ? event.target.files : null;
        },
        handleDeleteFile(index) {
            var files = Array.from(this.attachment)
            files.splice(index, 1)
            this.attachment = files;
        },
        handleDeleteAttachment(id) {
            this.$vs.loading();
            const basePath = this.po > 0 ? this.$store.state.purchase.poReturn.baseUrlPath : this.$store.state.purchase.supplierReturn.baseUrlPath
            this.$http.delete(`${this.$store.state.outbound.supplierReturn.baseUrlPath}/${this.id}/attachment/${id}`)
                .then((resp) => {
                    this.$vs.loading.close();
                    if (resp.code == 200) {
                        this.form.attachments = resp.data;
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle",
                        });
                    }
                });
        },
        handleSubmit(confirm = false) {
            var payload = Object.assign({}, this.form);
            try {
                if (this.form.note == "") {
                    throw "Required Note";
                }
                delete payload.warehouse
                var err = [];
                payload.lines.forEach((line, i) => {
                    payload.lines[i].return_qty = Number(line.return_qty)
                    if (payload.lines[i].return_qty == 0) {
                        err.push("quantity cannot be a zero value");
                    }
                });

                if (err.length > 0) {
                    throw new Error(err.join())
                }

                var formData = new FormData();
                formData.append("data", JSON.stringify(payload));
                if (this.attachment) {
                    this.attachment.forEach(item => {
                        formData.append("files", item);
                    });
                }
                this.$vs.loading();
                this.$http
                    .post(`${this.$store.state.outbound.supplierReturn.baseUrlPath}?confirm=${confirm}`, formData)
                    .then(resp => {
                        if (resp.code == 200) {
                            // reassign all id
                            console.log(resp);
                            this.form.id = resp.data.id;
                            this.id = resp.data.id;
                            resp.data.lines.forEach((item, index) => {
                                this.form.lines[index].id = item.id
                            })
                            this.$vs.loading.close();
                            if (confirm) {
                                this.handleBack();
                            } else {
                                this.$vs.dialog({
                                    type: "confirm",
                                    color: "success",
                                    title: `Updated Successfully`,
                                    text: "Updated data is successfully sent to server. Cancel to continue edit.",
                                    accept: this.handleBack,
                                });
                            }
                        } else {
                            this.$vs.loading.close();
                            this.$vs.dialog({
                                type: "confirm",
                                color: "danger",
                                title: `Error`,
                                text: `${resp.code} : ${resp.message}`,
                            });
                        }
                    });
            } catch (error) {
                this.$vs.dialog({
                    type: "confirm",
                    color: "danger",
                    title: `Error`,
                    text: `${error}`,
                });
            }
            this.$vs.loading.close();
        },
        handleBack() {
            this.$vs.loading();
            this.$router.push({
                name: `${this.$store.state.outbound.supplierReturn.baseRouterName}.index`,
            });
            this.$vs.loading.close();
        },
        initData() {
            this.$vs.loading();
            this.$http.get(`${this.$store.state.outbound.supplierReturn.baseUrlPath}/${this.id}`, {
                params: {
                    rid: this.return_id,
                    type: this.type,
                }
            }).then((resp) => {
                if (resp.code == 200) {
                    this.$vs.loading.close();
                    this.form = resp.data;
                    this.id = Number(this.form.id);
                    this.type = Number(this.form.return_type);
                    this.return_id = Number(this.form.return_id);
                    this.form.lines.forEach((x, i) => {
                        if (x.return_qty < 1) {
                            this.form.lines[i].return_qty = Number(x.quantity)
                        }
                        this.form.lines[i].remaining = Number(x.quantity) - Number(x.return_qty)
                        this.form.lines[i].batch_data = {
                            batch: x.batch,
                            expired_date: x.expired_date,
                        }
                    });
                } else {
                    this.$vs.loading.close();
                    this.$vs.dialog({
                        type: "confirm",
                        color: "danger",
                        title: `Error`,
                        text: `${resp.code} : ${resp.message}. Do you want to refetch the data?`,
                        accept: this.initData,
                    });
                }
            });
        }
    },
    computed: {
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('YYYY-MM-DD');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                return Number(val).toLocaleString("id-ID");
            };
        },
    },
}
</script>

<style>
.upload {
    display: flex;
}

.upload button.up {
    margin: 0px !important;
}

.upload button.btnx {
    margin-left: 10px !important;
    border-radius: 5px 0px 0px 5px;
}

.upload button.btn-drop {
    border-radius: 0px 5px 5px 0px;
    border-left: 1px solid rgba(255, 255, 255, .2);
}
</style>